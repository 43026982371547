import './dialog.css'
import React from 'react';

export default class Dialog extends React.Component {
    constructor(props) {
      super(props);
      this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onClose();
    }

    render() {
        return (
            <div className='modal'>
                <section className='dialog'>
                    <img className='close-image' src='/icons/close.png' alt='Close' onClick={this.handleClose} />
                    {this.props.children}
                </section>
            </div>
        );
    }    
}
  