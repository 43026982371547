import './groups.css'
import React from 'react'
import ObjectSelector from './object-selector';
import DataApi from '../common/data-api';
import * as Algo from '../common/algo'
import * as Constants from '../common/constants'
import * as Document from '../common/document'
import * as Utility from '../common/utility'
import Object from '../components/object'

export default class Groups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: undefined,
            groups: undefined,
            currentGroup: undefined,
            subGroups: undefined,
            currentSubGroup: undefined,
            objects: undefined,
            browserState: undefined,
        };
        this.handleGroups = this.handleGroups.bind(this);
        this.handleObjects = this.handleObjects.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleSubGroupChange = this.handleSubGroupChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tab === state.tab && props.browserState === state.browserState) {
            return {};
        }
        let currentGroup = props.browserState !== state.browserState && props.browserState !== undefined
            ? props.browserState.group : undefined;
        return {
            tab: props.tab,
            groups: undefined,
            currentGroup: currentGroup === undefined ? (props.tab === 'characters' ? 0 : undefined) : currentGroup,
            currentSubGroup: props.browserState !== state.browserState && props.browserState !== undefined
                ? props.browserState.subGroup : undefined,
            objects: undefined,
            browserState: props.browserState,
        };
    }

    handleGroups(data) {
        let subGroups;
        let groups = [];
        this.removeDuplicates(data).forEach(row => {
            if (this.props.tab === 'creators') {
                if (subGroups === undefined) {
                    subGroups = new Map();
                }
                if (subGroups.get(row['id']) === undefined) {
                    subGroups.set(row['id'], [[Constants.roleNames[row['role']], row['role']]]);
                    groups.push([row['name'], row['id']]);
                } else {
                    subGroups.get(row['id']).push([Constants.roleNames[row['role']], row['role']]);
                }
            } else if (this.props.tab === 'characters') {
                if (subGroups === undefined) {
                    subGroups = new Map();
                }
                if (subGroups.get(row['team']) === undefined) {
                    subGroups.set(row['team'], [[row['name'], row['id']]]);
                    groups.push([Constants.characterTypes[row['team']], row['team']]);
                } else {
                    subGroups.get(row['team']).push([row['name'], row['id']]);
                }
            } else {
                groups.push([row['name'], row['id']]);
            }
        });
        this.sortGroups(groups);
        if (subGroups !== undefined) {
            subGroups.forEach(subGroup => {
                if (this.props.tab === 'creators') {
                    this.sortRoles(subGroup);
                } else if (this.props.tab === 'characters') {
                    subGroup.sort();
                }
            });
        }
        this.props.onValidDatesChange(undefined);
        this.setState({ groups, subGroups }, () => {
            Document.setTitle({
                ...this.state.browserState,
                groupName: this.getGroupName(this.state.browserState.group, this.state.browserState.subGroup),
                subGroupName: this.getSubGroupName(this.state.browserState.subGroup)
            });
        });
    }

    handleObjects(data) {
        data.forEach(object => {
            const img = new Image();
            img.src = Utility.getImageSource(this.getObjectType(), object, 'xlarge');
        });
        let objects = Utility.convertToDateMap(data);
        for (let date of objects.keys()) {
            objects.set(date, this.sortObjects(this.mergeObjects(objects.get(date))));
        }
        this.setState({ objects });
        this.props.onValidDatesChange([...objects.keys()]);
    }

    handleGroupChange(group) {
        let subGroup = (this.props.tab === 'characters' || this.state.subGroups === undefined)
            ? undefined
            : this.state.subGroups.get(parseInt(group))[0][1];
        Document.setState({
            ...this.state.browserState,
            group: group,
            groupName: this.getGroupName(group, subGroup),
            subGroup: subGroup,
            subGroupName: this.getSubGroupName(subGroup),
        });
        this.props.onValidDatesChange(undefined);
        this.setState({
            currentGroup: group,
            currentSubGroup: subGroup,
            objects: undefined,
        });
    }

    handleSubGroupChange(subGroup) {
        Document.setState({
            ...this.state.browserState,
            group: this.state.currentGroup,
            groupName: this.getGroupName(this.state.currentGroup, subGroup),
            subGroup: subGroup,
            subGroupName: this.getSubGroupName(subGroup),
        });
        this.setState({
            currentSubGroup: subGroup,
            objects: undefined,
        })
    }

    getObjectType() {
        return (
            {
                'series': 'issues',
                'features': 'stories',
                'creators': 'stories',
                'characters': 'stories',
            }[this.props.tab]
        )
    }

    getGroupName(group, subGroup) {
        if (group === undefined) {
            return undefined;
        } else if (this.props.tab === "characters") {
            for (const element of this.state.subGroups.get(parseInt(group))) {
                if (element[1] === parseInt(subGroup)) {
                    return element[0];
                }
            }
        } else {
            for (const element of this.state.groups) {
                if (element[1].toString() === group.toString()) {
                    return element[0];
                }
            }
        }
    }

    getSubGroupName(subGroup) {
        if (subGroup === undefined || this.props.tab !== "creators") {
            return undefined;
        }
        return Constants.roleNames[subGroup];
    }

    removeDuplicates(data) {
        if (this.props.tab === 'creators') {
            return data;
        }
        let groups = new Map();
        data.forEach(row => {
            let key = row['id'];
            if (this.props.tab === 'characters') {
                key = key + row['team'] * 1000000;
            }
            if (groups.get(key) === undefined ||
                row['year'] > groups.get(key)['year'] ||
                (row['year'] === groups.get(key)['year'] && row['month'] > groups.get(key)['month'])) {
                groups.set(key, row);
            }
        });
        return [...groups.values()];
    }

    sortGroups(groups) {
        groups.sort((a, b) => {
            if (a[0] < b[0]) {
                return -1;
            } else if (a[0] > b[0]) {
                return 1;
            }
            return 0;
        });
    }

    sortRoles(roles) {
        roles.sort((a, b) => {
            if (a[1] < b[1]) {
                return -1;
            } else if (a[1] > b[1]) {
                return 1;
            }
            return 0;
        });
    }

    mergeObjects(objects) {
        if (this.props.tab === 'creators' || this.props.tab === 'characters') {
            return Algo.mergeObjects(objects);
        }
        return objects;
    }

    sortObjects(objects) {
        if (this.props.tab === 'series') {
        } else if (this.props.tab === 'features') {
            objects.sort((a, b) => {
                if (a['issue'] !== b['issue'] &&
                    a['rating'] !== b['rating']) {
                    return b['rating'] - a['rating'];
                } else if (a['id'] !== b['id']) {
                    return a['id'] - b['id'];
                }
                return 0;
            });
        } else if (this.props.tab === 'creators' || this.props.tab === 'characters') {
            Algo.sortStories(objects);
        }
        return objects;
    }

    render() {
        if (this.state.groups === undefined) {
            return <DataApi resource={this.props.tab} handleData={this.handleGroups} />
        }

        return (
            <>
                <ObjectSelector
                    objectType={this.props.tab === 'characters' ? 'Character Type' : this.props.tab}
                    objects={this.state.groups}
                    selectedObject={this.state.currentGroup}
                    onSelectionChange={this.handleGroupChange}
                />
                {
                    this.state.currentGroup !== undefined &&
                    this.state.subGroups !== undefined &&
                    this.state.subGroups.get(parseInt(this.state.currentGroup)) !== undefined &&
                    <ObjectSelector
                        objectType={this.props.tab === 'creators' ? 'role' : Constants.characterTypes[this.state.currentGroup]}
                        objects={this.state.subGroups.get(parseInt(this.state.currentGroup))}
                        selectedObject={this.state.currentSubGroup}
                        onSelectionChange={this.handleSubGroupChange}
                    />
                }
                {
                    this.state.currentGroup !== undefined &&
                    this.state.currentGroup !== '' &&
                    this.state.objects === undefined &&
                    (
                        (
                            this.props.tab === 'series' &&
                            <DataApi
                                resource='series-issues'
                                argument={'series=' + this.state.currentGroup}
                                handleData={this.handleObjects}
                            />
                        ) || (
                            this.props.tab === 'features' &&
                            <DataApi
                                resource='feature-stories'
                                argument={'feature=' + this.state.currentGroup}
                                handleData={this.handleObjects}
                            />
                        ) || (
                            this.props.tab === 'creators' &&
                            this.state.currentSubGroup !== undefined &&
                            <DataApi
                                resource='creator-stories'
                                argument={
                                    'creator=' + this.state.currentGroup +
                                    '&role=' + this.state.currentSubGroup
                                }
                                handleData={this.handleObjects}
                            />
                        ) || (
                            this.props.tab === 'characters' &&
                            this.state.currentSubGroup !== undefined &&
                            <DataApi
                                resource='character-stories'
                                argument={
                                    'type=' + Constants.characterTypes[this.state.currentGroup] +
                                    '&id=' + this.state.currentSubGroup
                                }
                                handleData={this.handleObjects}
                            />
                        )
                    )
                }
                <div className='group-images-box'>
                    {
                        this.state.objects !== undefined &&
                        this.state.objects.get(this.props.date) !== undefined &&
                        this.state.objects.get(this.props.date).map(object =>
                            <Object
                                key={object['id']}
                                objectType={this.getObjectType()}
                                object={object}
                                imageSize='xlarge'
                            />
                        )
                    }
                </div>
            </>
        )
    }
}
