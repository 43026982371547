import './tab-selector.css'
import React from 'react'
import * as Document from '../common/document'
import { Tabs } from '../common/tabs'
import * as Utility from '../common/utility'

export default class TabSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleFirstTabButtonClick = this.handleFirstTabButtonClick.bind(this);
        this.handleSecondTabButtonClick = this.handleSecondTabButtonClick.bind(this);

        this.state = {
            selectedTab: undefined,
            selectedSubTab: undefined,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedTab === state.selectedTab && props.selectedSubTab === state.selectedSubTab) {
            return {};
        }
        return {
            selectedTab: props.selectedTab,
            selectedSubTab: props.selectedSubTab,
        };
    }

    handleFirstTabButtonClick(event) {
        let tab = event.target.id;
        let subTab = Tabs.get(tab).keys().next().value;
        this.handleTabChange(tab, subTab);
    }

    handleSecondTabButtonClick(event) {
        let tab = this.props.selectedTab;
        let subTab = event.target.id;
        this.handleTabChange(tab, subTab);
    }

    handleTabChange(tab, subTab) {
        this.props.onTabChange([tab, subTab]);
        Document.setState({tab: tab, subTab: subTab});
    }

    render() {
        return (
            <>
                <div className='tab-selector-row'>
                    {
                        [...Tabs.keys()].map(tab =>
                            <button
                                id={tab}
                                key={tab}
                                type='button'
                                className={this.state.selectedTab === tab ? 'tab-button-selected' : 'tab-button'}
                                onClick={this.handleFirstTabButtonClick}
                            >
                                {Utility.firstLetterUppercase(tab)}
                            </button>
                        )
                    }
                </div>
                <div className='tab-selector-row'>
                    {
                        [...Tabs.get(this.state.selectedTab).keys()].map(tab =>
                            <button
                                id={tab}
                                key={tab}
                                type='button'
                                className={this.state.selectedSubTab === tab ? 'tab-button-selected' : 'tab-button'}
                                onClick={this.handleSecondTabButtonClick}
                            >
                                {Tabs.get(this.state.selectedTab).get(tab)['title']}
                            </button>
                        )
                    }
                </div>
            </>
        )
    }
}
