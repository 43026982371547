export const siteName = 'Marvel Age';

export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const roleNames = [
    "Script",
    "Pencils",
    "Inks",
    "Colors",
    "Letters",
];

export const characterTypes = [
    "Character",
    "Team",
];