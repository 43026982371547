import './statistics.css'
import React from 'react';
import DateSlider from '../frame/date-slider';
import Characters from './characters';
import Creators from './creators';
import Features from './features';
import Series from './series';

export default class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: props.dateRange[0],
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyPress);
    }

    handleDateChange(date) {
        this.setState({ date });
    }

    handleKeyPress(event) {
        if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
            return;
        }
        
        event.preventDefault();

        if (event.key === 'ArrowRight') {
            if (this.state.date < this.props.dateRange[1]) {
                this.handleDateChange(this.state.date + 1);
                return;
            }
        } else if (event.key === 'ArrowLeft') {
            if (this.state.date > this.props.dateRange[0]) {
                this.handleDateChange(this.state.date - 1);
                return;
            }
        }
    }

    render() {
        return (
            <div className='timelapse-box'>
                {
                    {
                        'series': <Series date={this.state.date} />,
                        'features': <Features date={this.state.date} />,
                        'creators': <Creators dateRange={this.props.dateRange} date={this.state.date} />,
                        'characters': <Characters dateRange={this.props.dateRange} date={this.state.date} />,
                    }[this.props.tab]
                }
                <DateSlider dateRange={this.props.dateRange} date={this.state.date} onDateChange={this.handleDateChange} />
            </div>
        );
    }
}
