import './timeline.css'
import React from 'react';
import * as Navigation from './navigation'
import Year from './year.js'
import DataApi from '../common/data-api';
import * as Algo from '../common/algo'
import * as Utility from '../common/utility'

export default class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: undefined,
            objects: undefined,
            selectedObject: undefined,
        };
        this.handleData = this.handleData.bind(this);
        this.handleObjectClick = this.handleObjectClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tab === state.tab &&
            state.settings &&
            props.settings.readingOrder === state.settings.readingOrder) {
            return {};
        }
        return {
            tab: props.tab,
            settings: props.settings,
            objects: undefined,
            selectedObject: undefined,
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyPress);
    }
    
    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyPress);
    }

    handleData(data) {
        this.setState({objects: this.organizeObjects(data)});
    }

    handleObjectClick(object) {
        this.navigateToObject(object);
    }

    handleKeyPress(event) {
        if (
            event.key !== 'ArrowUp' && event.key !== 'ArrowDown' &&
            event.key !== 'ArrowLeft' && event.key !== 'ArrowRight'
        ) {
            return;
        } else if (this.state.objects === undefined) {
            return;
        } else if (this.props.tab === 'reading-order') {
            return;
        }
        
        event.preventDefault();

        if (this.state.selectedObject === undefined) {
            this.navigateToObject(this.state.objects.values().next().value.values().next().value[0]);
            return;
        }

        let year = this.state.selectedObject['year'];
        let month = this.state.selectedObject['month'];

        if (event.key === 'ArrowDown') {
            let maxYear = Utility.numberToDate(this.props.dateRange[1])['year'];
            for (let y = year; y <= maxYear; ++y) {
                let yearObjects = this.state.objects.get(y);
                for (let m = month + 1; m <= 12; ++m) {
                    if (this.navigateToMonthObject(yearObjects.get(m))) {
                        return;
                    }
                }
                month = 0;
            }
        } else if (event.key === 'ArrowUp') {
            let minYear = Utility.numberToDate(this.props.dateRange[0])['year'];
            for (let y = year; y >= minYear; --y) {
                let yearObjects = this.state.objects.get(y);
                for (let m = month - 1; m >= 1; --m) {
                    if (this.navigateToMonthObject(yearObjects.get(m))) {
                        return;
                    }
                }
                month = 13;
            }
        } else {
            let monthObjects = this.state.objects.get(year).get(month);
            for (let i = 0; i < monthObjects.length; ++i) {
                if (monthObjects[i]['id'] === this.state.selectedObject['id']) {
                    let j = event.key === 'ArrowRight' ? i + 1 : i - 1;
                    if (j >= 0 && j < monthObjects.length) {
                        this.navigateToObject(monthObjects[j]);
                    }
                    return;
                }
            }
        }
    }

    navigateToMonthObject(monthObjects) {
        if (monthObjects === undefined) {
            return false;
        }
        for (let i = 0; i < monthObjects.length; ++i) {
            let object = monthObjects[i];
            if (Navigation.areFromSameGroup(this.props.tab, object, this.state.selectedObject)) {
                this.navigateToObject(object);
                return true;
            }
        }
        return false;
    }

    navigateToObject(object) {
        this.setState({selectedObject: object});
        document.getElementById(object['id']).scrollIntoView({behavior: 'smooth', block: 'center'});
    }

    organizeObjects(data) {
        let years = new Map();

        data.forEach(object => {
            if (years.get(object['year']) === undefined) {
                let months = new Map();
                months.set(object['month'], [object]);
                years.set(object['year'], months);
            } else {
                let months = years.get(object['year']);
                if (months.get(object['month']) === undefined) {
                    months.set(object['month'], [object]);
                } else {
                    months.get(object['month']).push(object);
                }
            }
        });

        years.forEach(months =>
            months.forEach((monthObjects, month) => {
                months.set(month, this.sortObjects(this.mergeObjects(monthObjects)))
            })
        );

        return years;
    }

    mergeObjects(objects) {
        if (this.props.tab !== 'issues') {
            return Algo.mergeObjects(objects);
        }
        return objects;
    }

    sortObjects(objects) {
        if (this.props.tab === 'issues') {
            objects.sort((a, b) => {
                if (a['rating'] !== b['rating']) {
                    return b['rating'] - a['rating'];
                } else if (a['id'] !== b['id']) {
                    return a['id'] - b['id'];
                }
                return 0;
            });
        } else if (this.props.tab === 'stories') {
            Algo.sortStories(objects);
        } else if (this.props.tab === 'reading-order') {
            objects.sort((a, b) => {
                if (a['issue_rating'] !== b['issue_rating']) {
                    return b['issue_rating'] - a['issue_rating'];
                } else if (a['issue_id'] !== b['issue_id']) {
                    return a['issue_id'] - b['issue_id'];
                } else if (a['id'] !== b['id']) {
                    return a['id'] - b['id'];
                }
                return 0;
            });
        }
        return objects;
    }

    render() {
        if (this.state.objects === undefined) {
            let argument = this.props.tab === 'reading-order'
                ? ('order=' + (this.props.settings.readingOrder === 'all' ? '2' : '1'))
                : undefined;
            return <DataApi resource={this.props.tab} argument={argument} handleData={this.handleData} />
        }

        let yearBlocks = [];
        this.state.objects.forEach((yearObjects, year) => yearBlocks.push(
            <Year
                key={year}
                year={year}
                objectType={this.props.tab}
                objects={yearObjects}
                selectedObject={this.state.selectedObject}
                settings={this.props.settings}
                onObjectClick={this.handleObjectClick}
            />
        ));

        return (
            <div>
                {yearBlocks}
            </div>
        )
    }
}
