import './radio.css'
import React from 'react';

export default class Radio extends React.Component {
    constructor(props) {
      super(props);
      this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleValueChange(event) {
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <div className='radio'>
                <div>{this.props.setting}:</div>
                {this.props.values.map(value =>
                    <div key={value[0]} className='radio-option'>
                        <label key={value[0]}>
                            <input
                                type='radio'
                                name={this.props.setting}
                                value={value[0]}
                                checked={value[0] === this.props.selectedValue}
                                onChange={this.handleValueChange}
                            />
                            {value[1]}
                        </label>
                    </div>
                )}
            </div>
        );
    }
}
