import './common.css'
import React from 'react'
import Bar from './bar'
import DataApi from '../common/data-api';
import * as Utility from '../common/utility'

export default class Features extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateMap: undefined,
        };
        this.handleData = this.handleData.bind(this);
    }

    handleData(data) {
        this.setState({
            dateMap: Utility.sortDateMap(Utility.convertToDateMap(data), 'rating', 'name')
        });
    }

    render() {
        if (this.state.dateMap === undefined) {
            return <DataApi resource='feature-ratings' handleData={this.handleData} />
        }

        let data = Utility.getDataForDate(this.state.dateMap, this.props.date);
        if (data === undefined) {
            return <div className='no-data'>No data</div>
        }

        const max = data[0]['rating'];
        let rows = [];
        data.slice(0, 25).forEach(row => rows.push(
            <tr key={row['name'] + row['rating']}>
                <td className='name-field'>{row['name']}</td>
                <td className='number-field'>{row['rating']}</td>
                <td><Bar percentage={100 * row['rating'] / max} /></td>
            </tr>
        ));

        return (
            <table className='stat-table'>
                <thead>
                    <tr>
                        <th className='name-header'>Name</th>
                        <th>Rating</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }
}
