import './year.css'
import React from 'react';
import MonthBlock from './month-block.js'
import MonthRow from './month-row.js'

export default function Year(props) {

    let monthBlocks = [];
    props.objects.forEach((monthObjects, month) =>
        monthBlocks.push(props.objectType === 'reading-order'
            ?
                <MonthBlock
                    key={month}
                    month={month}
                    objects={monthObjects}
                    settings={props.settings}
                />
            :
                <MonthRow
                    key={month}
                    month={month}
                    objectType={props.objectType}
                    objects={monthObjects}
                    selectedObject={props.selectedObject}
                    settings={props.settings}
                    onObjectClick={props.onObjectClick}
                />
        )
    );

    return (
        <div>
            <div className='year-header'>{props.year}</div>
            {monthBlocks}
        </div>
    )
}
