import React from 'react';
import StatisticsInfo from '../statistics/info';
import TimelapseInfo from '../timelapse/info'
import TimelineInfo from '../timeline/info'

export default function Info(props) {
    return (
        (
            props.tab === 'timeline' &&
            <TimelineInfo subTab={props.subTab} />
        ) || (
            props.tab === 'timelapse' &&
            <TimelapseInfo subTab={props.subTab} />
        ) || (
            props.tab === 'statistics' &&
            <StatisticsInfo />
        )
    );
}
