import './object.css'
import React from 'react';
import * as Utility from '../common/utility'

export default class Object extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick(this.props.object);
        }
    }

    getAlt() {
        if (this.props.objectType === 'issues') {
            return this.props.object['name'] + " #" + this.props.object['number'];
        } else if (this.props.objectType === 'stories') {
            return (
                this.props.object['feature'] === null
                    ? this.props.object['title']
                    : (
                        this.props.object['feature'] +
                        (
                            this.props.object['title'] === ''
                                ? ''
                                : (': ' + this.props.object['title'])
                        )
                    )
            );
        }
    }

    getTitleComponent() {
        if (this.props.objectType === 'issues') {
            return (
                <>
                    <div className={'title-' + this.props.imageSize}>
                        {this.props.object['name']}
                    </div>
                    <div className={'title-' + this.props.imageSize}>
                        {"#" + this.props.object['number']}
                    </div>
                </>
            )
        } else if (this.props.objectType === 'stories') {
            return (
                <>
                    <div className={'title-' + this.props.imageSize}>
                        {this.props.object['feature'] === null ? '' : this.props.object['feature']}
                    </div>
                    <div className={'subtitle-' + this.props.imageSize}>
                        {this.props.object['title']}
                    </div>
                </>
            )
        }
    }

    render () {
        return (
            <div
                id={this.props.object['id']}
                className={'object-box-' + this.props.imageSize}
                onClick={this.handleClick}
            >
                <div
                    className={
                        this.props.selected
                            ? 'selection-box'
                            : (this.props.highlighted ? 'highlight-box' : 'invisible-box')
                    }
                >
                    <div className='image-box'>
                        <img
                            src={
                                Utility.getImageSource(
                                    this.props.objectType,
                                    this.props.object,
                                    this.props.imageSize
                                )
                            }
                            alt={this.getAlt()}
                            className='image'
                            loading='lazy'
                        />
                    </div>
                    {this.getTitleComponent()}
                </div>
            </div>
        );
    }
}
