import './month-row.css'
import React from 'react';
import * as Navigation from './navigation'
import * as Constants from '../common/constants'
import Object from '../components/object'

export default class MonthRow extends React.Component {

    shouldHiglightObject(object) {
        if (!this.props.selectedObject) {
            return false;
        }
        return Navigation.areFromSameGroup(this.props.objectType, object, this.props.selectedObject);
    }

    render() {
        return (
            <div className='month-row'>
                <div className='month-header'>{Constants.monthNames[this.props.month - 1]}</div>
                <div className='object-row'>
                    {
                        this.props.objects.map(object =>
                            <Object
                                key={object['id']}
                                objectType={this.props.objectType}
                                object={object}
                                imageSize={this.props.settings.imageSize}
                                selected={object === this.props.selectedObject}
                                highlighted={this.shouldHiglightObject(object)}
                                onClick={this.props.onObjectClick}
                            />
                        )
                    }
                </div>
                <div className='object-strecher'></div>
            </div>
        );
    }

}
