import React from 'react';

let SubTabInfos = {
    'series':
        <div>
            In this view you can follow the evaluation of a particular magazine series
            over time. Just select one and use the time slider below. You can also use
            left and right arrow keys for the same purpose.
        </div>,
    'features':
        <div>
            In this view you can follow the evaluation of a particular feature over time.
            Just select one and use the time slider below. You can also use left and right
            arrow keys for the same purpose.
        </div>,
    'creators':
        <div>
            In this view you can go through all stories a particular creator contributed to.
            Just select a creator and the contribution role, then use the time slider below.
            You can also use left and right arrow keys for the same purpose.
        </div>,
    'characters':
        <div>
            In this view you can go through all stories a particular character or a team participated in.
            Just select a character or team, then use the time slider below.
            You can also use left and right arrow keys for the same purpose.
        </div>,
}

export default function TimelapseInfo(props) {
    return SubTabInfos[props.subTab];
}
