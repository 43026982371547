import './date-slider.css'
import React from 'react';
import * as Constants from '../common/constants'
import * as Utility from '../common/utility'

export default class DateSlider extends React.Component {
    constructor(props) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleDateChange(event) {
        this.props.onDateChange(Number(event.target.value));
    }

    getBackgroundColor() {
        if (!this.props.validDates) {
            return 'white';
        }
        let gradient = 'linear-gradient(to right';

        let min = this.props.dateRange[0];
        let max = this.props.dateRange[1];

        function percent(date) {
            return 100 * (date - min) / (max - min);
        }

        let date = this.props.validDates[0];
        if (date === min) {
            gradient += ',green 0%';
        } else {
            gradient += ',white 0%';
            gradient += ',white ' + percent(date) + '%';
            gradient += ',green ' + percent(date) + '%';
        }
        let prev = date;
        for (let i = 1; i < this.props.validDates.length; i++) {
            date = this.props.validDates[i];
            if (date !== prev + 1) {
                gradient += ',green ' + percent(prev + 1) + '%';
                gradient += ',white ' + percent(prev + 1) + '%';
                gradient += ',white ' + percent(date) + '%';
                gradient += ',green ' + percent(date) + '%';
            }
            prev = date;
        }
        date = this.props.validDates[this.props.validDates.length - 1];
        if (date === max) {
            gradient += ',green 100%';
        } else {
            gradient += ',green ' + percent(date + 1) + '%';
            gradient += ',white ' + percent(date + 1) + '%';
            gradient += ',white 100%';
        }

        gradient += ')';
        return gradient;
    }

    render() {
        let date = Utility.numberToDate(this.props.date);
        return (
            <div className='date-slider'>
                <div className='date-text'>
                    {date['year'] + "/" + Constants.monthNames[date['month'] - 1]}
                </div>
                <input
                    type='range'
                    className='slider'
                    style={{background: this.getBackgroundColor()}}
                    min={this.props.dateRange[0]}
                    max={this.props.dateRange[1]}
                    value={this.props.date}
                    onChange={this.handleDateChange}
                />
            </div>
        );
    }
}
