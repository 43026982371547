import './bar.css'
import React from 'react';

export default function Bar(props) {

    const background = 'linear-gradient(to right' +
        ',rgba(255,255,255,1) ' + props.percentage + '%' +
        ',rgba(255,255,255,0) ' + props.percentage + '%' +
        ',rgba(255,255,255,0) 100%';

    return (
        <div className='bar' style={{background: background}} />
    );
}
