import React from 'react';
import Radio from './radio';

export const Settings = {
    imageSize: 'small',
    readingOrder: 'all',
};

export class SettingsDialog extends React.Component {
    constructor(props) {
      super(props);
      this.handleImageSizeChange = this.handleImageSizeChange.bind(this);
      this.handleReadingOrderChange = this.handleReadingOrderChange.bind(this);
    }

    handleImageSizeChange(value) {
        this.props.onSubmit({
            ...this.props.settings,
            imageSize: value,
        });
    }

    handleReadingOrderChange(value) {
        this.props.onSubmit({
            ...this.props.settings,
            readingOrder: value,
        });
    }

    render() {
        return (
            <div>
                <Radio
                    setting='Image size'
                    values={[['small', 'small'], ['large', 'large']]}
                    selectedValue={this.props.settings.imageSize}
                    onChange={this.handleImageSizeChange}
                />
                {
                    this.props.subTab === 'reading-order' &&
                    <Radio
                        setting='Reading order'
                        values={[['all', 'all notable events'], ['major', 'only major events']]}
                        selectedValue={this.props.settings.readingOrder}
                        onChange={this.handleReadingOrderChange}
                    />
                }
            </div>
        );
    }    
}
  