import './app.css'
import React from 'react'
import Error from './error'
import Home from './home'
import DataApi from './common/data-api'
import * as Constants from './common/constants'
import * as Document from './common/document'
import { Tabs } from './common/tabs'
import * as Utility from './common/utility'
import Info from './control/info'
import { Settings, SettingsDialog } from './control/settings'
import ControlButtonGroup from './control/button-group'
import Dialog from './frame/dialog'
import Footer from './frame/footer'
import TabSelector from './frame/tab-selector'
import Title from './frame/title'
import Statistics from './statistics/statistics'
import Timelapse from './timelapse/timelapse'
import Timeline from './timeline/timeline'

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.handleDateRange = this.handleDateRange.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleSettingsUpdate = this.handleSettingsUpdate.bind(this);

        let browserState = this.extractBrowserState();

        if (browserState.tab === undefined) {
            Document.updateHeader(
                Constants.siteName + ' - Page not found',
                'Page not found',
                '/404'
            );
        } else if (browserState.tab === '') {
            Document.updateHeader(
                Constants.siteName,
                'Interactive history of Marvel Comics',
                ''
            );
        } else {
            Document.setTitle(browserState);
        }

        this.state = {
            dateRange: undefined,
            dialog: undefined,
            settings: Settings,
            browserState: browserState,
        };

        window.addEventListener("popstate", (event) => {
            this.handleNewBrowserState(event.state);
        });
    }

    extractBrowserState() {
        let path = window.location.pathname.split('/');
        let tab = undefined;
        let subTab = undefined;
        if (path[1] === '') {
            tab = '';
            subTab = '';
        } else {
            tab = path[1].toLowerCase();
            if (Tabs.get(tab) === undefined || path[2] === undefined) {
                tab = undefined;
            } else {
                subTab = path[2].toLowerCase();
                if (Tabs.get(tab).get(subTab) === undefined) {
                    tab = undefined;
                    subTab = undefined;
                }
            }
        }
        return {
            tab,
            subTab,
            group: path[3],
            subGroup: path[4],
        };
    }

    handleNewBrowserState(state) {
        if (!state) {
            state = this.extractBrowserState();
        }
        Document.setTitle(state);
        this.setState({
            browserState: state,
        });
    }

    handleDateRange(dateRange) {
        this.setState({ dateRange: [Utility.dateToNumber(dateRange[0]), Utility.dateToNumber(dateRange[1])] });
    }

    handleTabChange(tab) {
        this.setState({
            browserState: {
                tab: tab[0],
                subTab: tab[1],
                group: undefined,
                subGroup: undefined,
            },
        });
    }

    toggleDialog(dialog) {
        this.setState({ dialog });
    }

    handleSettingsUpdate(settings) {
        this.setState({
            settings: settings
        })
    }

    render() {
        if (this.state.browserState.tab === undefined) {
            return (
                <div className='body-box'>
                    <div className='header-box'>
                        <Title />
                    </div>
                    <div className='content-box'>
                        <Error />
                    </div>
                </div>
            );
        } else if (this.state.browserState.tab === '') {
            return (
                <div className='body-box'>
                    <div className='header-box'>
                        <Title />
                    </div>
                    <div className='content-box'>
                        <Home />
                    </div>
                </div>
            );
        }

        if (this.state.dateRange === undefined) {
            return <DataApi resource='date-range' handleData={this.handleDateRange} />
        }

        return (
            <div className='body-box'>
                <div className='header-box'>
                    <div className='header-title'>
                        <Title />
                    </div>
                    <div className='header-tabs'>
                        <TabSelector
                            selectedTab={this.state.browserState.tab}
                            selectedSubTab={this.state.browserState.subTab}
                            onTabChange={this.handleTabChange}
                        />
                    </div>
                    <div className='header-buttons'>
                        <ControlButtonGroup tab={this.state.browserState.tab} onClick={this.toggleDialog} />
                    </div>
                </div>
                <div className='content-box'>
                    {
                        (
                            this.state.browserState.tab === 'timeline' &&
                            <Timeline
                                dateRange={this.state.dateRange}
                                tab={this.state.browserState.subTab}
                                settings={this.state.settings}
                            />
                        ) || (
                            this.state.browserState.tab === 'timelapse' &&
                            <Timelapse
                                dateRange={this.state.dateRange}
                                tab={this.state.browserState.subTab}
                                browserState={this.state.browserState}
                            />
                        ) || (
                            this.state.browserState.tab === 'statistics' &&
                            <Statistics dateRange={this.state.dateRange} tab={this.state.browserState.subTab} />
                        )
                    }
                </div>
                <div className='footer-box'>
                    <Footer />
                </div>
                {
                    this.state.dialog !== undefined &&
                    <Dialog onClose={this.toggleDialog}>
                        {
                            (
                                this.state.dialog === 'settings' &&
                                <SettingsDialog
                                    subTab={this.state.browserState.subTab}
                                    settings={this.state.settings}
                                    onSubmit={this.handleSettingsUpdate}
                                />
                            ) || (
                                this.state.dialog === 'info' &&
                                <Info tab={this.state.browserState.tab} subTab={this.state.browserState.subTab} />
                            )
                        }
                    </Dialog>
                }
            </div>
        );
    }
}
