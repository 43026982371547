import './timelapse.css'
import React from 'react';
import Groups from './groups';
import DateSlider from '../frame/date-slider';

export default class Timelapse extends React.Component {
    constructor(props) {
        super(props);
        this.date = undefined;
        this.timeout = undefined;
        this.state = {
            tab: undefined,
            date: props.dateRange[0],
            validDates: undefined,
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handlePostponedDateChange = this.handlePostponedDateChange.bind(this);
        this.handleValidDatesChange = this.handleValidDatesChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tab === state.tab) {
            return {};
        }
        return {
            tab: props.tab,
            date: props.dateRange[0],
            validDates: undefined,
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyPress);
    }

    handleDateChange(date) {
        if (this.timeout === undefined) {
            this.date = date;
            this.timeout = setTimeout(this.handlePostponedDateChange, 100);
        }
    }

    handlePostponedDateChange() {
        this.setState({ date: this.date });
        this.timeout = undefined;
    }

    handleValidDatesChange(validDates) {
        this.setState({
            validDates: validDates,
            date: validDates !== undefined ? validDates[0] : this.props.dateRange[0],
        });
    }

    handleKeyPress(event) {
        if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
            return;
        } else if (this.state.validDates === undefined) {
            return;
        }
        
        event.preventDefault();

        if (event.key === 'ArrowRight') {
            for (let i = 0; i < this.state.validDates.length; ++i) {
                if (this.state.date < this.state.validDates[i]) {
                    this.handleDateChange(this.state.validDates[i]);
                    return;
                }
            }
        } else if (event.key === 'ArrowLeft') {
            for (let i = this.state.validDates.length - 1; i >= 0; --i) {
                if (this.state.date > this.state.validDates[i]) {
                    this.handleDateChange(this.state.validDates[i]);
                    return;
                }
            }
        }
    }

    render() {
        return (
            <div className='timelapse-box'>
                <Groups
                    tab={this.props.tab}
                    date={this.state.date}
                    browserState={this.props.browserState}
                    onValidDatesChange={this.handleValidDatesChange}
                />
                <DateSlider
                    dateRange={this.props.dateRange}
                    date={this.state.date}
                    validDates={this.state.validDates}
                    onDateChange={this.handleDateChange}
                />
            </div>
        );
    }
}
