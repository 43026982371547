export function dateToNumber(date) {
    return date['year'] * 12 + date['month'] - 1;
}

export function numberToDate(number) {
    return {
        year: Math.floor(number / 12),
        month: number % 12 + 1,
    }
}

export function getDataForDate(dateMap, date) {
    let data = dateMap.get(date);
    if (data !== undefined) {
        return data;
    } else if (dateMap.get(date + 1) === undefined) {
        return undefined;
    }
    return dateMap.get(date - 1);
}

export function convertToDateMap(data) {
    let dateMap = new Map();
    data.forEach(row => {
        let key = dateToNumber(row);
        if (dateMap.get(key) === undefined) {
            dateMap.set(key, [row]);
        } else {
            dateMap.get(key).push(row);
        }
    });
    return dateMap;
}

export function sortArray(array, numberFieldName, nameFieldName) {
    array.sort((a, b) => {
        if (a[numberFieldName] !== b[numberFieldName]) {
            return b[numberFieldName] - a[numberFieldName];
        } else if (a[nameFieldName] < b[nameFieldName]) {
            return -1;
        } else if (a[nameFieldName] > b[nameFieldName]) {
            return 1;
        }
        return 0;
    });
}

export function sortDateMap(dateMap, numberFieldName, nameFieldName) {
    dateMap.forEach(array => sortArray(array, numberFieldName, nameFieldName));
    return dateMap;
}

export function fillMissingValues(dateMap, dateRange) {
    for (let i = dateRange[0] + 1; i <= dateRange[1]; ++i) {
        if (dateMap.get(i) === undefined) {
            dateMap.set(i, new Map());
        }
        let map = dateMap.get(i);
        dateMap.get(i - 1).forEach((value, key) => {
            if (map.get(key) === undefined) {
                map.set(key, value);
            }
        });
    }
}

const imageHeights = new Map([
    ['small', 256],
    ['large', 512],
    ['xlarge', 1024]
]);

export function getImageSource(objectType, object, imageSize) {
    if (object['image'] === null) {
        return '';
    }
    return (
        '/images/' +
        objectType.toLowerCase() +
        '/' +
        object['year'] +
        '/' +
        String(object['month']).padStart(2, '0') +
        '/' +
        object['image'] +
        '_' +
        imageHeights.get(imageSize) +
        '.jpg'
    );
}

export function firstLetterUppercase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
