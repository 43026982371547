import './common.css'
import React from 'react';
import Bar from './bar'
import DataApi from '../common/data-api';
import * as Utility from '../common/utility'

export default class Characters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateMap: undefined,
        };
        this.handleData = this.handleData.bind(this);
    }

    characterToNumber(character) {
        return character['id'] * 2 + character['team'];
    }

    handleData(data) {
        let dateMap = new Map();
        data.forEach(row => {
            let date = Utility.dateToNumber(row);
            if (dateMap.get(date) === undefined) {
                dateMap.set(date, new Map());
            }
            let characterKey = this.characterToNumber(row);
            dateMap.get(date).set(characterKey, { name: row['name'], team: row['team'], pages: row['pages'] });
        });
        Utility.fillMissingValues(dateMap, this.props.dateRange);
        this.setState({ dateMap });
    }

    getDataForCurrentDate() {
        let array = [...this.state.dateMap.get(this.props.date).values()];
        Utility.sortArray(array, 'pages', 'name');
        return array.slice(0, 25);
    }

    render() {
        if (this.state.dateMap === undefined) {
            return <DataApi resource='character-pages' handleData={this.handleData} />
        }

        let data = this.getDataForCurrentDate();
        const max = data[0]['pages'];
        let rows = [];
        data.forEach(row => rows.push(
            <tr key={row['name'] + row['pages']}>
                <td className='name-field'>
                    {
                        row['team'] === 1
                            ? '<' + row['name'] + '>'
                            : row['name']
                    }
                </td>
                <td className='number-field'>{row['pages']}</td>
                <td><Bar percentage={100 * row['pages'] / max} /></td>
            </tr>
        ));

        return (
            <table className='stat-table'>
                <thead>
                    <tr>
                        <th className='name-header'>Name</th>
                        <th>Pages</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }
}
