import React from 'react';

export default function StatisticsInfo(props) {
    return (
        <div>
            This view is about numbers and numbers and numbers.
            <br /><br />
            <b>Creators:</b> Here you can find the number of pages written,
            drawn, inked, colored or lettered by comic creators.
            <br /><br />
            <b>Characters:</b> Here the numbers represent those pages, where
            the particular character was drawn at least once.
            <br /><br />
            <b>Features:</b> Numbers here are calculated by a special homemade
            formula, which tries to represent the popularity or importance of a
            particular feature compared with others. The formula is based on
            the number of pages of each feature story, who are the creators and
            which characters are used, as well as some other parameters.
            <br /><br />
            <b>Series:</b> This is similar to the above, but the aggregation is
            done for all stories in magazines of the particular series.
            <br /><br /><br />
            You can navigate through time by the slider below, or
            alternatively using the left and right arrow keys.
        </div>
    );
}
