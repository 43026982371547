//import './button-group.css'
import React from 'react';
import ControlButton from './button';

const TabControls = new Map([
    ['timeline', ['info', 'settings']],
    ['timelapse', ['info']],
    ['statistics', ['info']],
]);

export default function ControlButtonGroup(props) {
    return (
        <>
            {
                TabControls.get(props.tab).map(control =>
                    <ControlButton key={control} type={control} onClick={props.onClick} />
                )
            }
        </>
    );
}
