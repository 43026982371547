import React from 'react';

let SubTabInfos = {
    'issues':
        <div>
            This view presents MC comic issues shown on a vertical timeline, each row
            presenting a single month. You can scroll horizontally to see all issues
            of a specific month, or scroll vertically to navigate through time.
            <br /><br />
            Alternatively you can use arrow keys for navigation. Remember only, that up
            and down arrows will navigate to the previous and next issue of the same series.
            <br /><br />
            You can also change the size of the images from the Settings.
        </div>,
    'stories':
        <div>
            This view presents MC comic stories shown on a vertical timeline, each row
            presenting a single month. You can scroll horizontally to see all stories
            of a specific month, or scroll vertically to navigate through time.
            <br /><br />
            Alternatively you can use arrow keys for navigation. Remember only, that up
            and down arrows will navigate to the previous and next story of the same series.
            <br /><br />
            You can also change the size of the images from the Settings.
        </div>,
    'reading-order':
        <div>
            Here you can find stories representing important events of the MC timeline.
            <br /><br />
            You can change the size of the images from the Settings. The Reading Order
            is better viewed with larger images.
            <br /><br />
            You can also chose to show only major events or all notable ones from the Settings.
        </div>,
}

export default function TimelineInfo(props) {
    return SubTabInfos[props.subTab];
}
