import './button.css'
import React from 'react';

export default class ControlButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.type);
    }

    render() {
        return (
            <img
                className='control-button-image'
                alt={this.props.type}
                src={'/icons/' + this.props.type + '.png'}
                onClick={this.handleClick}
            />
        );
    }
}
