import './home.css'
import React from 'react';
import { Tabs } from './common/tabs'
import * as Utility from './common/utility'

export default function Home(props) {
    return (
        <div className='home-box'>
            {
                [...Tabs.keys()].map(group =>
                    <div key={group} className='group-box'>
                        {Utility.firstLetterUppercase(group)}
                    {
                        [...Tabs.get(group).keys()].map(tab =>
                            <div key={tab} className='tab-box'>
                                <div className='tab-link-box'>
                                    <a
                                        className='tab-link'
                                        href={group + '/' + tab}
                                    >
                                        {Tabs.get(group).get(tab)['title']}
                                    </a>
                                </div>
                                <div className='tab-text'>
                                    {Tabs.get(group).get(tab)['description']}
                                </div>
                            </div>
                        )
                    }
                    </div>
                )
            }
        </div>
    )
}
