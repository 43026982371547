export const Tabs = new Map([
    ['timeline', new Map([
        ['issues', {
            'title': 'Issues',
            'description': 'Navigate Marvel Comics timeline by magazine issues',
        }],
        ['stories', {
            'title': 'Stories',
            'description': 'Navigate Marvel Comics timeline by comic stories',
        }],
        ['reading-order', {
            'title': 'Reading Order',
            'description': 'Navigate Marvel Comics timeline by reading order',
        }],
    ])],
    ['timelapse', new Map([
        ['series', {
            'title': 'Series',
            'description': 'See a series of Marvel Comics magazine issues in a time-lapse view',
            'groupDescription': 'See a time-lapse view of the Marvel Comics magazine - ',
        }],
        ['features', {
            'title': 'Features',
            'description': 'See comic stories of a Marvel Comics feature in a time-lapse view',
            'groupDescription': 'See a time-lapse view of the Marvel Comics feature - ',
        }],
        ['creators', {
            'title': 'Creators',
            'description': 'See comic stories by a Marvel Comics creator in a time-lapse view',
            'groupDescription': 'See a time-lapse view of comic stories by the Marvel Comics creator - ',
        }],
        ['characters', {
            'title': 'Characters',
            'description': 'See comic stories with a Marvel Comics character in a time-lapse view',
            'groupDescription': 'See a time-lapse view of comic stories with Marvel Comics character - ',
        }],
    ])],
    ['statistics', new Map([
        ['series', {
            'title': 'Series',
            'description': 'Ratings of Marvel Comics series calculated by a special formula',
        }],
        ['features', {
            'title': 'Features',
            'description': 'Ratings of Marvel Comics features calculated by a special formula',
        }],
        ['creators', {
            'title': 'Creators',
            'description': 'Number of pages contributed by different Marvel Comics creators',
        }],
        ['characters', {
            'title': 'Characters',
            'description': 'Number of pages different Marvel Comics characters appeared on',
        }],
    ])],
]);
