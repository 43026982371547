import './object-selector.css'
import React from 'react';

export default class ObjectSelector extends React.Component {
    constructor(props) {
      super(props);
      this.handleSelectionChange = this.handleSelectionChange.bind(this);
    }

    handleSelectionChange(event) {
        this.props.onSelectionChange(event.target.value);
    }

    hasSelection() {
        if (this.props.selectedObject === undefined) {
            return false;
        }
        for (var object of this.props.objects) {
            if (object[1] === parseInt(this.props.selectedObject)) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <select
                id={this.props.objectType}
                className='selector'
                onChange={this.handleSelectionChange}
                value={this.hasSelection() ? this.props.selectedObject : ''}
            >
                {
                    !this.hasSelection() &&
                    <option key=''>--Choose {this.props.objectType}--</option>
                }
                {
                    this.props.objects.map(row =>
                        <option key={row[0]} value={row[1]}>{row[0]}</option>
                    )
                }
            </select>
        );
    }
}
