import './month-block.css'
import React from 'react';
import * as Constants from '../common/constants'
import Object from '../components/object'

export default class MonthBlock extends React.Component {

    groupByIssue() {
        let issues = new Map();

        this.props.objects.forEach(object => {
            if (issues.get(object['issue_id']) === undefined) {
                issues.set(object['issue_id'], [object]);
            } else {
                issues.get(object['issue_id']).push(object);
            }
        });

        return issues;
    }

    getIssueObject(object) {
        return {
            id: object['issue_id'],
            year: object['year'],
            month: object['month'],
            name: object['issue_name'],
            number: object['issue_number'],
            image: object['issue_image'],
        };
    }

    getStoryObject(object) {
        return {
            id: object['id'],
            year: object['year'],
            month: object['month'],
            feature: object['feature'],
            title: object['story_title'],
            image: object['story_image'],
        };
    }

    render() {
        let issues = this.groupByIssue();

        return (
            <>
                <div className='month-block-header'>{Constants.monthNames[this.props.month - 1]}</div>
                <>
                    {
                        Array.from(issues.keys()).map(issue_id =>
                            <div key={issue_id} className='issue-block'>
                                <Object
                                    objectType='issues'
                                    object={this.getIssueObject(issues.get(issue_id)[0])}
                                    imageSize={this.props.settings.imageSize}
                                />
                                <div>
                                    {
                                        issues.get(issue_id).map(story =>
                                            <div key={story['id']} className='story-block'>
                                                <Object
                                                    objectType='stories'
                                                    object={this.getStoryObject(story)}
                                                    imageSize={this.props.settings.imageSize}
                                                />
                                                <div className='story-comment'>
                                                    {story['comment']}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </>
            </>
        );
    }
}
