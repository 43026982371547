import './title.css'
import React from 'react';
import * as Constants from '../common/constants'

export default function Title(props) {
    return (
        <header>
            <a className='title-link' href='/'><h1 className='main-title'>{Constants.siteName}</h1></a>
            <h3 className='sub-title'>Interactive history of Marvel Comics</h3>
        </header>
    );
}
