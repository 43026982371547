import './common.css'
import './creators.css'
import React from 'react';
import Bar from './bar'
import DataApi from '../common/data-api';
import * as Constants from '../common/constants'
import * as Utility from '../common/utility'

export default class Creators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateMap: undefined,
        };
        this.handleData = this.handleData.bind(this);
    }

    creatorToNumber(creator) {
        return creator['id'] * 5 + creator['role'];
    }

    handleData(data) {
        let dateMap = new Map();
        data.forEach(row => {
            let date = Utility.dateToNumber(row);
            if (dateMap.get(date) === undefined) {
                dateMap.set(date, new Map());
            }
            let creatorKey = this.creatorToNumber(row);
            dateMap.get(date).set(creatorKey, { name: row['name'], role: row['role'], pages: row['pages'] });
        });
        Utility.fillMissingValues(dateMap, this.props.dateRange);
        this.setState({ dateMap });
    }

    getDataForCurrentDate() {
        let array = [...this.state.dateMap.get(this.props.date).values()];
        array.sort((a, b) => {
            if (a['pages'] !== b['pages']) {
                return b['pages'] - a['pages'];
            } else if (a['role'] !== b['role']) {
                return a['role'] - b['role'];
            } else if (a['name'] < b['name']) {
                return -1;
            } else if (a['name'] > b['name']) {
                return 1;
            }
            return 0;
        });
        return array.slice(0, 25);
    }

    render() {
        if (this.state.dateMap === undefined) {
            return <DataApi resource='creator-pages' handleData={this.handleData} />
        }

        let data = this.getDataForCurrentDate();
        const max = data[0]['pages'];
        let rows = [];
        data.forEach(row => rows.push(
            <tr key={row['name'] + row['role'] + row['pages']}>
                <td className='name-field'>{row['name']}</td>
                <td className='role-field'>{Constants.roleNames[row['role']]}</td>
                <td className='number-field'>{row['pages']}</td>
                <td><Bar percentage={100 * row['pages'] / max} /></td>
            </tr>
        ));

        return (
            <table className='stat-table'>
                <thead>
                    <tr>
                        <th className='name-header'>Name</th>
                        <th className='role-header'>Role</th>
                        <th>Pages</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }
}
