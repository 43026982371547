export function mergeObjects(objects) {
    let map = new Map();
    objects.forEach(object => {
        let key = object['id'];
        let existingObject = map.get(key);
        if (existingObject === undefined) {
            map.set(key, object);
        } else if (!existingObject['feature'].includes(';')) {
            existingObject['feature_rating'] += object['feature_rating'];
            existingObject['feature'] = existingObject['feature_rating'] >= object['feature_rating']
                ? existingObject['feature'] + '; ' + object['feature']
                : object['feature'] + '; ' + existingObject['feature'];
        }
    });
    return [...map.values()];
}

export function sortStories(stories) {
    stories.sort((a, b) => {
        if (a['feature_rating'] === null && b['feature_rating'] !== null) {
            return 1;
        } else if (a['feature_rating'] !== null && b['feature_rating'] === null) {
            return -1;
        } else if (a['feature_rating'] !== null && b['feature_rating'] !== null &&
                    a['feature_rating'] !== b['feature_rating']) {
            return b['feature_rating'] - a['feature_rating'];
        } else if (a['issue'] !== b['issue'] &&
                    a['story_rating'] !== b['story_rating']) {
            return b['story_rating'] - a['story_rating'];
        } else if (a['id'] !== b['id']) {
            return a['id'] - b['id'];
        }
        return 0;
    });
}