import './error.css'
import React from 'react';

export default function Error(props) {
    return (
        <div className='error-text'>
            404
            <br />
            Page not found
        </div>
    )
}
