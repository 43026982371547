export function areFromSameGroup(objectsType, object1, object2) {
    if (objectsType === 'issues') {
        return object1['series'] === object2['series'];
    } else if (objectsType === 'stories') {
        if (!object1['feature'] || !object2['feature']) {
            return false;
        } if (object1['feature_id'] === object2['feature_id']) {
            return true;
        }
        return object1['feature'].includes(object2['feature']) || object2['feature'].includes(object1['feature']);
    }
    return false;
}
