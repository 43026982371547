import * as Constants from './constants'
import { Tabs } from './tabs'
import * as Utility from './utility'

export function setState(state) {
    window.history.pushState(state, null, getPath(state));
    setTitle(state);
}

export function setTitle(state) {
    updateHeader(getTitle(state), getDescription(state), getPath(state));
}

export function updateHeader(title, description, path) {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute('content', description);
    document.querySelector('link[rel="canonical"]').setAttribute('href', 'https://www.marvel-age.com' + path);
}

function getTitle(state) {
    return Constants.siteName + ' - ' +
        Utility.firstLetterUppercase(state.tab) + ' - ' +
        Tabs.get(state.tab).get(state.subTab)['title'] +
        (state.groupName === undefined ? '' : (' - ' + state.groupName)) +
        (state.subGroupName === undefined ? '' : (' - ' + state.subGroupName));
}

function getDescription(state) {
    return state.groupName === undefined
        ? Tabs.get(state.tab).get(state.subTab)['description']
        : (
            Tabs.get(state.tab).get(state.subTab)['groupDescription'] + state.groupName +
            (state.subGroupName === undefined ? '' : (' - ' + state.subGroupName))
        );
}

function getPath(state) {
    return (
        '/' + state.tab +
        '/' + state.subTab +
        (state.group === undefined ? '' : ('/' + state.group)) +
        (state.subGroup === undefined ? '' : ('/' + state.subGroup))
    );
}
